@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* Hide Google Translate Toolbar */
.goog-te-banner-frame {
  display: none !important;
}

/* Hide Google Translate Pop-up */
body {
  top: 0px !important;
}

/* Hide Google Translate Widget Box */
.goog-te-gadget {
  display: none !important;
}

/* Hide Google Translate Toolbar */
.goog-te-banner-frame {
  display: none !important;
}
.goog-te-banner-frame.skiptranslate {
  display: none !important;
}

/* Hide the Google Translate iframe */
iframe.skiptranslate {
  display: none !important;
}

/* Prevent body from shifting */
body {
  top: 0px !important;
  position: static !important;
}

/* Hide the Google Translate Widget */
.goog-te-gadget {
  display: none !important;
}
